<template>
  <div
    class="container page"
    style="background-color: #1b1b1b; min-height: 100vh"
  >
    <van-nav-bar title="미션기록" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()" />
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="Success"
      >
        <template #pulling> 스크롤하여 새로 고침하세요 </template>
        <template #loosing> 스크롤하여 새로 고침하세요 </template>
        <template #loading> 로딩 중 </template>
        <van-empty
          v-if="list.length === 0"
          description="데이터가 비어 있습니다!"
        />
        <div
          v-else
          class="item_list"
          v-for="(v, key) in list"
          :key="key"
          style="background-color: #1b1b1b; border: 1px solid #ffcc99"
        >
          <div class="lottery_info">
            <van-image class="cover" :src="v.ico">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <span class="period-number" style="color: #fff; font-size: 13px">{{
              v.expect
            }}</span>
            <span class="period-number" style="color: #ffcc99">{{
              v.name
            }}</span>
          </div>
          <div style="display: flex; align-items: center; padding: 16px 0">
            <div
              v-if="v.status !== 0"
              style="
                border: 1px solid #ffcc99;
                border-radius: 8px;
                padding: 4px;
                width: 15%;
                text-align: center;
                color: #ffcc99;
                font-size: 12px;
                margin-right: 10px;
              "
            >
              {{ ["복", "록", "수", "희"][v.opencode[0] - 1] }}
            </div>
            <div
              v-if="v.status !== 0"
              style="
                border: 1px solid #ffcc99;
                border-radius: 8px;
                padding: 4px;
                width: 15%;
                text-align: center;
                color: #ffcc99;
                font-size: 12px;
                margin-right: 10px;
              "
            >
              {{ ["복", "록", "수", "희"][v.opencode[1] - 1] }}
            </div>
            <!-- <div
              v-if="v.status !== 0"
              style="
                border: 1px solid #ffcc99;
                border-radius: 8px;
                padding: 4px;
                width: 15%;
                text-align: center;
                color: #ffcc99;
                font-size: 12px;
                margin-right: 10px;
              "
            >
              {{ ["미", "캔디", "비행기", "요트"][v.opencode[2] - 1] }}
            </div> -->

            <!-- <div style="margin-left: auto">
              <span
                class="res-des middle"
                style="color: #fff; font-size: 13px; width: max-content"
                >{{ v.status === 0 ? 0 : v.opencode[0] + v.opencode[1] }}</span
              >
              <span
                class="res-des middle"
                style="
                  color: #fff;
                  font-size: 13px;
                  width: max-content;
                  padding: 0 10px;
                "
                >{{v.status === 0 ? 0 : (v.opencode[0] + v.opencode[1] + v.opencode[2]) >= 11 && (v.opencode[0] + v.opencode[1] + v.opencode[2]) &lt;= 18 ? "Huge" : "Little girl"}}</span
              >
              <span
                class="res-des middle"
                style="color: #fff; font-size: 13px; width: max-content"
                >{{
                  v.status === 0
                    ? 0
                    : (v.opencode[0] + v.opencode[1] + v.opencode[2]) % 2 === 0
                    ? "Bisexuality"
                    : "One"
                }}</span
              >
            </div> -->
          </div>

          <!-- <div class="recent" style="color: #fff">
            <div class="kuaisan-ball left">
              <van-image
                class="res-img"
                :src="
                  v.status === 0
                    ? 'img/lottery/open_num.gif'
                    : 'img/lottery/shaizi/' + +'.png'
                "
              >
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <van-image
                class="res-img"
                :src="
                  v.status === 0
                    ? 'img/lottery/open_num.gif'
                    : 'img/lottery/shaizi/' + v.opencode[1] + '.png'
                "
              >
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <van-image
                class="res-img"
                :src="
                  v.status === 0
                    ? 'img/lottery/open_num.gif'
                    : 'img/lottery/shaizi/' + v.opencode[2] + '.png'
                "
              >
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
            </div>
          </div> -->

          <div style="display: flex; align-items: center; line-height: 1.4">
            <div>
              <div style="color: #fff; font-size: 12px">
                서비스 시간:{{ v.create_time }}
              </div>
              <div style="color: #fff; font-size: 12px">
                완료 시간:{{ v.update_time }}
              </div>
            </div>
            <div style="margin-left: auto">
              <div v-if="v.status === 1" style="color: #fff">
                {{ v.status_text }}
              </div>
              <div v-else>{{ v.status_text }}</div>
              <div style="color: #ffcc99">배팅 수:{{ v.money }}</div>
            </div>
          </div>
          <!--   <div class="topInfo">
            <span v-if="v.is_win === 1" style="color: #07c160">{{v.win_text}}</span>
            <span v-else >{{v.win_text}}</span>
            <span>金额：{{v.profit}}</span>
          </div> -->
          <!-- <div class="topInfo">
            <span>任务类型：</span>
            <span>{{v.type}}</span>
          </div> -->
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      isLoading: false,
      list: [],
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("성공");
        this.isLoading = false;
      }, 500);
    },
    getUserGameList() {
      this.$http({
        method: "get",
        url: "user_get_game_list",
      }).then((res) => {
        if (res.code === 200) {
          console.log(res.data);
          this.list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserGameList();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}

::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main {
  position: relative;
  overflow: auto;
  background-color: transparent;
  height: 100%;
  padding: 0 10px;
}
.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

// .item_list .topInfo span {
//   flex: 1;
//   font-size: 35px;
//   font-weight: 700;
//   color: #ff253f;
// }
// .item_list .time span {
//   flex: 1;
//   font-size: 25px;
//   font-weight: 500;
//   color: #000;
// }

// .item_list .topInfo span:last-child {
//   float: right;
// }
.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover {
  width: 60px;
  height: 60px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list .period-number {
  margin-left: 50px;
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}
.item_list .lottery_info {
  display: flex;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left {
  justify-content: flex-start;
}
.kuaisan-ball {
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img {
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des {
  font-weight: 700;
  text-align: center;
  color: #000;
}
.kuaisan-ball .res-des.middle {
  width: 15%;
  font-size: 35px;
}
</style>
